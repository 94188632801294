import React from 'react';
import { arrayOf, element } from 'prop-types';

import { ModalBodyContainer } from './styles';

function ModalBody({ panels }) {
  return (
    <ModalBodyContainer>
      {/* add keys to panelElements to prevent console warning */}
      {panels.reduce((panelsToRender, panel) => {
        if (panel) {
          // we need to clone the element to add a key, as a React element's props aren't extensible
          panel = React.cloneElement(panel, {
            // use next idx in panelsToRender for key
            // it's possible panels contains falsy values
            // so length of panelsToRender may differ from the panels prop received
            key: panelsToRender.length
          });
          panelsToRender.push(panel);
        }
        return panelsToRender;
      }, [])}
    </ModalBodyContainer>
  );
}

ModalBody.propTypes = {
  panels: arrayOf(element).isRequired
};

export default ModalBody;
