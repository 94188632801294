import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const AppointmentInfoContainer = styled.div`
  padding-bottom: ${fromTheme('spacing_medium')};

  h4 {
    margin-bottom: ${fromTheme('spacing_medium')};
  }
`;

export const Link = styled('a')`
  color: ${fromTheme('color_link')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const PleaseCallContainer = styled.div`
  div:not(:last-child) {
    margin-bottom: ${fromTheme('spacing_small')};
  }
`;
