import React, { Fragment, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from '@kyruus/intl';
import {
  SLOTS_LOADING_STATUS,
  SLOTS_SUCCESS_STATUS,
  SLOTS_ERROR_STATUS,
  MAX_AVAILABILITIES_TO_DISPLAY
} from '../../utils/constants';
import { scrollToTopCCF } from '../../utils/cerner';

import {
  LoadingAvailabilityTiles,
  VisibleAvailabilityTiles,
  ErrorAvailabilityTiles,
  formatAvailabilityForTiles,
  availabilityTilesTracking
} from '@kyruus/availability-tiles';

import { appendApptInfoToBookingUrl } from '@kyruus/provider-tile';
import { getRelativeParameterizedBookingUrl } from '../../utils/getRelativeParameterizedBookingUrl';

import AvailabilityModal from '../../search/availability-modal';
import { getPurposeOptionsByPatientRel } from '../../search/utils';
import { BOOK_TRACKING_PARAM_NAME } from '../../tracking/constants';

import {
  getProviderPhoneNumber_V8,
  getProviderDisplayName_V8,
  capitalizePatientRel,
  getProviderSlotsStatus
} from '../utils';

import { configSelector } from '../../behaviors/configuration/selectors';

import {
  AvailabilityHeader,
  ApptInfoWrapper,
  Button,
  LocationAvailabilityWrapper,
  LocationAvailabilityHeader
} from './styles';
import { getApptPurposeFilterProps } from '../../shared/appt-purpose-filtering';

const mapStateToProps = (state) => ({
  availabilityLoading: state.availability.loading,
  config: configSelector(state)
});

function ProfileAvailability({
  apptInfo,
  provider = {},
  availByLocId,
  slotsStatus,
  messages,
  config,
  Divider,
  fetchSlots,
  availablePurposesByProviderId,
  fetchPurposesByProviderId,
  log,
  availabilityLoading,
  location,
  tokens,
  intl
}) {
  const {
    direct_book: {
      patient_type: { buttons: availabilityButtonsConfig = [] } = {}
    } = {},
    visibility
  } = config;

  const patientRelOptions = getPurposeOptionsByPatientRel(
    provider,
    visibility.schedulingVisibility
  );

  const availablePurposes = availablePurposesByProviderId
    ? availablePurposesByProviderId[provider.id]
    : [];

  const [isAvailModalOpen, setIsAvailModalOpen] = useState(false);

  const [patientRel, setPatientRel] = useState(apptInfo.relationship);
  const [apptPurpose, setApptPurpose] = useState(apptInfo.purpose);

  const [editableApptInfo, setEditableApptInfo] = useState(apptInfo);

  const providerSlotsStatus = getProviderSlotsStatus(provider, slotsStatus);

  const toggleAvailabilityModal = () => {
    const modalWillOpen = !isAvailModalOpen;

    if (modalWillOpen) {
      fetchPurposesByProviderId(provider.id, log);
      scrollToTopCCF();
    }

    setIsAvailModalOpen(modalWillOpen);
  };

  let patientRelHasNoAvailability = false;
  if (!availabilityLoading && patientRel) {
    patientRelHasNoAvailability =
      availablePurposes &&
      availablePurposes[patientRel] &&
      availablePurposes[patientRel].size
        ? availablePurposes[patientRel].size === 0
        : true;
  }

  let purposeHasNoAvailability = false;
  if (!availabilityLoading && patientRel && apptPurpose) {
    purposeHasNoAvailability =
      availablePurposes &&
      availablePurposes[patientRel] &&
      availablePurposes[patientRel].size
        ? !availablePurposes[patientRel].has(apptPurpose)
        : true;
  }

  if (editableApptInfo && provider.locations) {
    return (
      <React.Fragment>
        <AvailabilityHeader>
          <FormattedMessage {...messages.profileAvailabilityHeaderText} />
        </AvailabilityHeader>
        <ApptInfoWrapper>
          <span>{capitalizePatientRel(editableApptInfo.relationship)}</span>
          <span> • </span>
          <span>{editableApptInfo.purpose}</span>
          <Button
            mode="flat"
            onClick={toggleAvailabilityModal}
            data-testid="AvailabilityEdit"
          >
            <FormattedMessage {...messages.profileAvailabilityEditBtnText} />
          </Button>
        </ApptInfoWrapper>
        {providerSlotsStatus === SLOTS_LOADING_STATUS &&
          provider.locations.map((loc, i) => (
            <Fragment key={loc.id}>
              {i > 0 ? Divider : null}
              <LocationAvailabilityWrapper>
                {loc.name && (
                  <LocationAvailabilityHeader>
                    {loc.name}
                  </LocationAvailabilityHeader>
                )}
                <LoadingAvailabilityTiles
                  maxAvailabilitiesToDisplay={MAX_AVAILABILITIES_TO_DISPLAY}
                  shouldDisplayAsOnMobile={true}
                />
              </LocationAvailabilityWrapper>
            </Fragment>
          ))}
        {providerSlotsStatus === SLOTS_SUCCESS_STATUS &&
          provider.locations
            .filter((loc) => {
              if (availByLocId) {
                return availByLocId[loc.id] && availByLocId[loc.id].length;
              } else {
                return true;
              }
            })
            .map((loc, idx) => {
              const bookingUrl = getRelativeParameterizedBookingUrl({
                provider,
                config,
                tokens,
                location,
                apptOptions: {
                  [BOOK_TRACKING_PARAM_NAME]:
                    availabilityTilesTracking.TRACKING_SOURCE_PROFILE
                }
              });
              return (
                <Fragment key={loc.id}>
                  {idx > 0 ? Divider : null}
                  <LocationAvailabilityWrapper>
                    {loc.name && (
                      <LocationAvailabilityHeader>
                        {loc.name}
                      </LocationAvailabilityHeader>
                    )}
                    <VisibleAvailabilityTiles
                      {...{
                        availabilities: formatAvailabilityForTiles(
                          availByLocId && availByLocId[loc.id],
                          bookingUrl
                        ),
                        providerId: provider.id,
                        maxAvailabilitiesToDisplay:
                          MAX_AVAILABILITIES_TO_DISPLAY,
                        moreAvailabilitiesLinkText: (
                          <FormattedMessage
                            {...messages.moreAvailabilitiesLinkText}
                          />
                        ),
                        displayLinkToViewMoreAvailabilities: true,
                        viewMoreBookOnlineUrl: appendApptInfoToBookingUrl(
                          apptInfo,
                          bookingUrl
                        ),
                        shouldDisplayAsOnMobile: true,
                        onTimeSlotsClick(date, time, provider_id) {
                          log(
                            'user_action.provider_timeslot_selected_provider_profile',
                            {
                              date,
                              time,
                              provider_id
                            }
                          );
                        }
                      }}
                    />
                  </LocationAvailabilityWrapper>
                </Fragment>
              );
            })}
        {providerSlotsStatus === SLOTS_ERROR_STATUS && (
          <ErrorAvailabilityTiles
            noMoreRetriesMsg={
              <FormattedMessage
                {...messages.availabilityErrorMsg}
                values={{ displayName: getProviderDisplayName_V8(provider) }}
              />
            }
            hasMoreRetries={false}
          />
        )}
        <AvailabilityModal
          isOpen={isAvailModalOpen}
          patientRel={patientRel}
          purpose={{ name: apptPurpose }}
          isPurposeLoading={availabilityLoading}
          onPatientRelSelect={(patientRel) => {
            setPatientRel(patientRel);
            setApptPurpose(null);
          }}
          onSelectPurpose={(e) => {
            setApptPurpose(e.name);
          }}
          handleCloseModal={toggleAvailabilityModal}
          handleConfirmModal={() => {
            const apptInfo = { relationship: patientRel, purpose: apptPurpose };
            fetchSlots(apptInfo, [provider.id]);
            setEditableApptInfo(apptInfo);
            toggleAvailabilityModal();
          }}
          patientRelOptions={patientRelOptions}
          providerDisplayName={getProviderDisplayName_V8(provider)}
          providerPhone={getProviderPhoneNumber_V8(provider)}
          availabilityButtonsConfig={availabilityButtonsConfig}
          purposeHasNoAvailability={purposeHasNoAvailability}
          patientRelHasNoAvailability={patientRelHasNoAvailability}
          apptPurposeFilterProps={getApptPurposeFilterProps({
            intl,
            config
          })}
        />
      </React.Fragment>
    );
  }
}

ProfileAvailability.propTypes = {
  apptInfo: PropTypes.shape({
    relationship: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired
  }),
  provider: PropTypes.shape({}),
  availByLocId: PropTypes.shape({}),
  currentAvailTilesState: PropTypes.shape({}),
  messages: PropTypes.shape({}),
  availabilityLoading: bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default connect(mapStateToProps)(injectIntl(ProfileAvailability));
