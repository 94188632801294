import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import querystring from 'querystring';

import { getProviderProfilePath } from 'Common/urls/provider';

const DR_TYPES = ['Physician', 'Resident - Physician', 'Fellow - Physician'];

const sortByAlpha =
  ({ order = 'asc' } = {}) =>
  (a, b) =>
    order == 'asc' ? a.localeCompare(b) : b.localeCompare(a);

function capitalizePatientRel(patientRel) {
  return patientRel.charAt(0).toUpperCase() + patientRel.slice(1);
}

function isDr(provider) {
  return DR_TYPES.includes(provider.provider_type);
}

// ------------------------------------------------------------------
// ------------------------------------------------------------------
// Search V8 utils
// ------------------------------------------------------------------
// ------------------------------------------------------------------

function getProviderDisplayName_V8(provider_profile) {
  var degrees = '';
  if (!_isEmpty(provider_profile.degrees)) {
    const degreeNames = provider_profile.degrees.map((degree) => degree.name);
    degrees = ', ' + degreeNames.join(', ');
  }

  return _get(provider_profile, 'name.full_name', '').concat(degrees);
}

function sortedLocations_V8(locations) {
  return _sortBy(locations, ['rank', 'distance', 'name']);
}

function specialtyNames_V8(provider) {
  return [
    ...new Set(
      (provider.specialties || []).map((specialty) => specialty.specialty)
    )
  ].sort(sortByAlpha());
}

function showCTA_V8(provider, config, hideCta) {
  var hideDisabledCta = hideCta || _get(config, 'modal_display.hide_cta');
  return !!(
    !hideDisabledCta ||
    provider.book_online_url ||
    provider.request_appointment_url ||
    provider.contact_phone ||
    provider.virtual_care_url
  );
}

function getProviderPhoneNumber_V8(provider) {
  const phone = provider.contacts.find(
    ({ contact_type }) => contact_type === 'phone'
  );

  if (phone) {
    return phone.value;
  }

  return null;
}

function getProviderSlotsStatus_V8(provider, slotsStatus) {
  return slotsStatus && slotsStatus[provider.id]
    ? slotsStatus[provider.id]
    : null;
}

// ------------------------------------------------------------------
// ------------------------------------------------------------------
// Search V9 utils
// ------------------------------------------------------------------
// ------------------------------------------------------------------

function getProviderDisplayName(provider) {
  const degrees = (provider.degrees || []).map((degree) => degree.name);
  return [provider.name.full].concat(degrees).join(', ');
}

function getProviderUrl({ provider, location, match, hash = '' }) {
  const pathname = getProviderProfilePath(provider);
  let search = location?.search || '';
  if (!search && match?.params?.specialty) {
    const nearParam = match?.params?.location
      ? `&location=${match.params.location}`
      : '';
    search = `?specialty_page=true&specialty=${match.params.specialty}${nearParam}`;
  }
  const url = `${pathname}${search}${hash}`;
  return url;
}

function getProviderSummaryUrl(
  provider,
  hash,
  slots,
  profileSummaryParams = {},
  location,
  match
) {
  const base = getProviderUrl({ provider, location, match, hash });
  const [path, search] = base.split('?');
  const [query, _hash] = (search || '').split('#');
  const baseQuery = querystring.parse(query);

  const updatedQuery = {
    ...baseQuery,
    ...profileSummaryParams
  };

  if (slots && slots[provider.id]) {
    const {
      apptInfo: { relationship, purpose }
    } = slots[provider.id];

    const updatedApptInfo = {
      ...updatedQuery,
      ...{
        relationship,
        purpose
      }
    };

    return `${path}?${querystring.stringify(updatedApptInfo)}${
      _hash ? `#${_hash}` : ''
    }`;
  }

  return `${path}?${querystring.stringify(updatedQuery)}${
    _hash ? `#${_hash}` : ''
  }`;
}

function sortedLocations(locations) {
  return _sortBy(locations, ['rank', 'distance', 'name']);
}

/**
 *
 * @param {Object} provider provider object
 * @returns {Array} alpha-sorted array of specialty names
 */
function specialtyNames(provider) {
  return [
    ...new Set((provider.specialties || []).map((specialty) => specialty.name))
  ].sort(sortByAlpha());
}

function showCTA(provider, config, hideCta) {
  const hideDisabledCta = hideCta || _get(config, 'modal_display.hide_cta');
  return !!(
    !hideDisabledCta ||
    provider.book_online_url ||
    provider.request_appointment_url ||
    provider.contact_phone ||
    provider.virtual_care_url
  );
}

function getProviderPhoneNumber(provider) {
  const phone = provider.contacts.find(
    ({ contact_type }) => contact_type === 'phone'
  );

  if (phone) {
    return phone.value;
  }

  return null;
}

function getProviderSlotsStatus(provider, slotsStatus) {
  return slotsStatus && slotsStatus[provider.id]
    ? slotsStatus[provider.id]
    : null;
}

export {
  getProviderDisplayName_V8,
  getProviderPhoneNumber_V8,
  sortedLocations_V8,
  specialtyNames_V8,
  showCTA_V8,
  getProviderSlotsStatus_V8,
  getProviderDisplayName,
  getProviderPhoneNumber,
  getProviderUrl,
  getProviderSummaryUrl,
  sortedLocations,
  specialtyNames,
  showCTA,
  getProviderSlotsStatus,
  capitalizePatientRel,
  isDr
};
