import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  found: {
    id: 'resultsheader.searchdescription.found',
    description: 'The number of providers returned for the search',
    defaultMessage: `{providerCount} {providerCount, plural,
      one {provider}
      other {providers}
    } found`
  },
  foundfornear: {
    id: 'resultsheader.searchdescription.foundfornear',
    description:
      'The number of providers returned for the search for a given search term near a location',
    defaultMessage: `{providerCount} {providerCount, plural,
      one {provider}
      other {providers}
    } found for {searchTerm} near {location}`
  },
  foundfor: {
    id: 'resultsheader.searchdescription.foundfor',
    description:
      'The number of providers returned for the search for a given search term',
    defaultMessage: `{providerCount} {providerCount, plural,
      one {provider}
      other {providers}
    } found for {searchTerm}`
  },
  foundnear: {
    id: 'resultsheader.searchdescription.foundnear',
    description:
      'The number of providers returned for the search near a location',
    defaultMessage: `{providerCount} {providerCount, plural,
      one {provider}
      other {providers}
    } found near {location}`
  },
  clearfilters: {
    id: 'resultsheader.clearfilters',
    description: 'Button text to clear all previously selected filters',
    defaultMessage: 'Clear All Filters'
  },
  locationName: {
    id: 'mapsearch.providerdrawer.selectedlocation',
    description: 'Map search provider drawer selected location name',
    defaultMessage: ' at {location}'
  },
  pmaUrlPreText: {
    id: 'pma.url.pretext',
    description: 'To make provider updates visit',
    defaultMessage: 'To make provider updates visit'
  },
  pmaUrlLabel: {
    id: 'pma.url.label',
    description: 'ProviderMatch Admin',
    defaultMessage: 'ProviderMatch Admin'
  },
  startNewSearch: {
    id: 'search.listing.start_a_new_search',
    description: 'Link to start a new search',
    defaultMessage: 'Start a new search'
  },
  searchHomeFilters: {
    id: 'search.home.filters',
    description:
      'Message displayed when search home with filters config is used',
    defaultMessage:
      'Your results are being filtered based on defaults chosen by your health system. Use the {clearAllFilters} link to show all providers.'
  },
  noapptpurposes: {
    id: 'error.no_appointment_purposes',
    description: 'Error for when the provider does not accept online booking',
    defaultMessage:
      'Sorry! {provider_name} does not accept {patient_status} patients via online booking.'
  },
  noapptpurposesGlobal: {
    id: 'error.no_appointment_purposes.global',
    description: 'Error for when all providers do not accept online booking',
    defaultMessage:
      "Sorry! We can't find any providers who accept {patient_status} patients via online booking."
  },
  noBookOnlineAvailabilityWarning: {
    id: 'provider.profile.apptInfo.noBookOnlineAvailabilitylWarning',
    description: 'Text to be displayed when a location has no availability',
    defaultMessage:
      'No online appointments available for {location}, call to schedule.'
  },
  establishedPatientType: {
    id: 'patient.established',
    description: 'Established patient type',
    defaultMessage: 'established'
  },
  newPatientType: {
    id: 'patient.new',
    description: 'New patient type',
    defaultMessage: 'new'
  }
});
