import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const ModalBodyContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 992px) {
    padding-bottom: ${fromTheme('spacing_medium')};
  }

  @media (max-width: 992px) {
    display: block;
  }
`;
