import React from 'react';
import { connect } from 'react-redux';
import url from 'url';

import { configSelector } from '../behaviors/configuration';

const DOMAIN_WHITELIST = [
  /^([\w.-]+\.)?kyruus.com$/,
  /^([\w.-]+\.)?provider-match.com$/
];

const hostUrlIsTrustworthy = (hostUrl, config) => {
  const { protocol, hostname } = hostUrl;
  const matchesCurrentHostname = hostname === config.host;
  const hostnameMatches = (trustedPattern) => trustedPattern.test(hostname);
  const matchesDomainWhitelist = DOMAIN_WHITELIST.some(hostnameMatches);
  const validProtocol = protocol === 'https:';
  return (
    ((matchesCurrentHostname || matchesDomainWhitelist) && validProtocol) ||
    protocol === 'tel:'
  );
};

/**
 * This should be used instead of <a> for all links that open in a new tab.
 * Behaves exactly like a normal anchor element, but with two differences:
 *   1. Will add target="_blank" so the link opens in a new tab. If another value
 *      for target is used, it will be ignored.
 *   2. If the href prop contains an untrusted URL, it will append
 *      "noopener noreferrer" to the rel attribute to prevent reverse tabnapping.
 *      https://sites.google.com/site/bughunteruniversity/nonvuln/phishing-with-window-opener
 * A URL is deemed trustworthy if the hostname matches one of the following:
 *   1. The current PMC hostname (e.g., hospitalname.org)
 *   2. provider-match.com (used for PMCDB)
 *   3. kyruus.com
 *   4. A phone number link (tel:)
 * Only Kyruus controlled domains are considered trustworthy.
 *
 * We need to pass the config with a 'host' property to check if the url is against the current host
 */
const SecureNewTabLinkComponent = ({
  href,
  rel,
  target,
  children,
  config = {},
  // note: Do not remove. Dispatch is destructured here to avoid errors generated by the dispatch property being spread to the anchor below.
  // eslint-disable-next-line no-unused-vars
  dispatch,
  ...props
}) => {
  let externalURL;
  try {
    externalURL = url.parse(href);
  } catch (e) {
    // Could not parse URL. Assume hostname is untrusted.
    externalURL = { hostname: null, protocol: null };
  }
  if (!hostUrlIsTrustworthy(externalURL, config)) {
    if (rel) {
      rel += ' noopener noreferrer';
    } else {
      rel = 'noopener noreferrer';
    }
  }

  if (!target && (typeof href === 'undefined' || !href.includes('tel:'))) {
    target = '_blank';
  }

  return (
    <a href={href} rel={rel} target={target} {...props}>
      {children}
    </a>
  );
};

function mapStateToProps(state) {
  return {
    config: configSelector(state)
  };
}

const SecureNewTabLink = connect(mapStateToProps)(SecureNewTabLinkComponent);

export default SecureNewTabLink;

export { SecureNewTabLinkComponent };
