import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import BaseButton from '@kyruus/button';

export const AvailabilityHeader = styled.h2`
  text-align: left;
  font-weight: ${fromTheme('font_weight_bold')};
`;

export const ApptInfoWrapper = styled.div`
  text-align: left;
  margin: ${fromTheme('spacing_medium')} 0;
`;

export const LocationAvailabilityWrapper = styled.div`
  margin: ${fromTheme('spacing_medium')} 0;
`;

export const LocationAvailabilityHeader = styled.h3`
  text-align: left;
  margin: ${fromTheme('spacing_medium')} 0;
  font-weight: ${fromTheme('font_weight_bold')};
`;

export const Button = styled(BaseButton)`
  border: 0;
  :active,
  :focus,
  :hover {
    background: transparent;
    text-decoration: underline;
  }
  color: ${fromTheme('color_link')};
`;
