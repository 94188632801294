import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from '@kyruus/intl';
import { Link } from './styles';

const messages = defineMessages({
  phoneNumberLinkTitle: {
    id: 'phoneNumberLink.title_attribute',
    description: 'Text for HTML title attribute for PhoneNumberLink',
    defaultMessage: 'Call {phone_number}'
  }
});

const PhoneNumberLink = ({ phone, log }) => {
  return (
    <Link
      href={`tel:${phone}`}
      title={<FormattedMessage {...messages.phoneNumberLinkTitle} />}
      onClick={() =>
        log('user_action.provider_availability_phone_number_selected')
      }
    >
      {phone}
    </Link>
  );
};

PhoneNumberLink.propTypes = {
  phone: PropTypes.string.isRequired
};

export default injectIntl(PhoneNumberLink);
