/**
 * Given a log function and a V9 provider, return a function similar to log
 * that includes tracking data for the given provider
 * @param {(eventName: string, eventData?: Object) => void} log
 * @param {*} provider
 * @returns
 */
export function getLogWithProvider(log, provider) {
  return (eventName, eventData) => {
    log(eventName, {
      ...eventData,
      provider_id: provider?.id,
      provider_name: provider?.name?.full
    });
  };
}

/**
 * Given a log function and a _V8_ provider, return a function similar to log
 * that includes tracking data for the given provider
 * @param {(eventName: string, eventData?: Object) => void} log
 * @param {*} provider
 * @returns
 */
export function getLogWithV8Provider(log, providerV8) {
  return (eventName, eventData) => {
    log(eventName, {
      ...eventData,
      provider_id: providerV8?.id,
      provider_name: providerV8?.name?.full_name
    });
  };
}
